<template>
<div>
<bar title="true" :titleText="$store.state.step.title" close="true"/>
<v-content>
  <message/>
	<v-container >
		<v-row>

      <v-col xs="6" md="6" v-if="$store.state.result.vsbot === true">
        <p class="text-right mb-0 font-weight-black" >{{mainVsbotMessage}}</p>
        <div class="text-right">
          <count-vsbot size="big" ref="countVsbot"/>
        </div>
        <p class="text-right mb-0 font-weight-black">{{$store.state.countVsbot}} {{countVsbotMessage}}</p>
      </v-col>

      <v-col xs="6" md="6" v-if="$store.state.result.basicSuccess === true">
        <p class="text-center mb-0 font-weight-black" >{{mainBasicSuccessMessage}}</p>
        <div class="text-center">
          <progress-basic :status="basicStatus" :total="basicTotal" :success="basicSuccess" :finished="false"/>
        </div>
        <p class="text-center mb-0 mt-2 font-weight-black">{{secondaryBasicSuccessMessage}}</p>
      </v-col>

      <v-col xs="6" md="6" v-if="typeof $store.state.result.progressAdvanced !== 'undefined'">
        <p class="text-center mb-0 font-weight-black" >{{mainAdvancedMessage}}</p>
        <div class="text-center">
          <progress-advanced :percentage="advancedPercentage" :finished="advancedFinished"/>
        </div>
        <p class="text-center mb-0 mt-2 font-weight-black">{{secondaryAdvancedMessage}}</p>
      </v-col>

      <v-col xs="6" md="6">
        <div class="text-left">
          <v-avatar size="150">
            <v-img style="animation: imageAnimation 4s" :src="$store.state.urlApi+'step/'+$store.state.step.id+'/image?auth='+$store.state.accessToken" />
          </v-avatar>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" md="12" v-if="typeof $store.state.result.progressToday !== 'undefined' && $store.state.result.progressToday.increaseWords > 0">
        <div class="text-center">
          <progress-today size="big"/>
        </div>
        <p class="text-center mt-2 font-weight-black">{{mainProgressTodayMessage}}</p>
      </v-col>
		</v-row>

    <v-row>
      <v-col xs="12" md="12" v-if="$store.state.result.increaseCoins && $store.state.result.increaseCoins > 0">
        <div class="text-center">
          <points size="big"/>
        </div>
        <p class="text-center mt-2 font-weight-black">{{mainIncreaseCoinsMessage}}</p>
      </v-col>
    </v-row>

	</v-container>

  <footer-button ref="footerButton" :redirection="$store.state.result.redirection" :name="$store.state.result.buttonName"/>

</v-content>
<simple-modal/>
</div>
</template>

<script>

import countVsbot from '../components/markers/CountVsbot.vue'
import bar from '../components/appComponents/Bar.vue'
import progressToday from '../components/markers/ProgressToday.vue'
import footerButton from '../components/appComponents/FooterButton.vue'
import progressBasic from '../components/markers/ProgressBasic.vue'
import progressAdvanced from '../components/markers/ProgressAdvanced.vue'
import points from '../components/markers/Points.vue'
import simpleModal from '../components/modals/SimpleModal.vue'
import message from '../components/appComponents/Message.vue'

export default {
  name: 'result',
  components: {
    bar,
    'count-vsbot' : countVsbot,
    'progress-today' : progressToday,
    'footer-button' : footerButton,
    'progress-basic' : progressBasic,
    'progress-advanced' : progressAdvanced,
    'points' : points,
    'simple-modal' : simpleModal,
    message
  },
  data() {
    return {
      timeout: false,
      countVsbotMessage: '',
      mainVsbotMessage: '...',
      mainProgressTodayMessage: '...',
      basicStatus: 1,
      basicTotal: 1,
      basicSuccess: false,
      mainBasicSuccessMessage: '...',
      secondaryBasicSuccessMessage: '',
      advancedPercentage: 0,
      advancedFinished: false,
      mainAdvancedMessage: '...',
      secondaryAdvancedMessage: '',
      mainIncreaseCoinsMessage: '...'
    }
  },
  beforeMount: function(){
    this.$store.state.refreshHome = true
    let that = this
    this.vsBotStart()
    this.basicStart()
    this.progressTodayStart()
    this.advancedStart()
    this.timeout = setTimeout(function(){
      that.vsBotEnd()
      that.basicEnd()
      that.advancedEnd()
      that.progressTodayEnd()
      that.increaseCoinsEnd()
      that.initWinAudio()
      that.$refs.footerButton.enable()
    }, 1500)
    
  },
  mounted: function(){
    if (this.$store.state.anonymous && this.$store.state.storage !== 'localStorage') {
      this.$root.$emit('changeMessage', { show : true , text : 'Atención, perderás todo tu progreso cuando cierres el navegador, si no quieres que esto ocurra, puedes ir a Ajustes y crear una cuenta' } )
    }
  },
  beforeDestroy(){
    if (this.timeout){
      clearTimeout(this.timeout)
    }
    this.$store.state.result = false
  },
  methods: {
    initWinAudio: function(){
      new Audio('bigwin.wav').play()
    },
    advancedStart: function(){
      if (typeof this.$store.state.result.progressAdvanced === 'undefined'){
        return
      }
      this.advancedPercentage = this.$store.state.step.percentage
      if (!this.$store.state.result.progressAdvanced.finished){
        if (!this.$store.state.step.toLock){
          this.$store.state.result.buttonName = 'intentar de nuevo'
          this.$store.state.result.redirection = '/study'
        }
        this.$store.state.step.percentage = this.$store.state.step.percentage + this.$store.state.result.progressAdvanced.increasePercentage
      }
    },
    advancedEnd: function(){
      if (typeof this.$store.state.result.progressAdvanced === 'undefined'){
        return
      }
      this.advancedPercentage = this.$store.state.step.percentage
      this.advancedFinished = this.$store.state.result.progressAdvanced.finished
      
      if (this.$store.state.result.progressAdvanced.increaseWords === 1){
        this.mainAdvancedMessage = '+ 1 palabra acertada!'
      } else {
        this.mainAdvancedMessage = '+ '+this.$store.state.result.progressAdvanced.increaseWords+' palabras acertadas!'
      }

      if (this.advancedFinished){
        this.secondaryAdvancedMessage = '¡Modo avanzado superado!'
        return
      }

      let wordsToEnd = this.$store.state.result.progressAdvanced.totalWords - this.$store.state.result.progressAdvanced.words
      if (wordsToEnd > 1){
        this.secondaryAdvancedMessage = 'Te faltan '+wordsToEnd+' palabras para acabar el modo avanzado'
      } else {
        this.secondaryAdvancedMessage = 'Te falta una palabra para acabar el modo avanzado'
      }
      
    },
    basicStart: function(){
      if (this.$store.state.result.basicSuccess !== true){
        return
      }
      this.basicStatus = this.$store.state.step.status
      this.basicTotal = this.$store.state.step.total
      let count = this.basicTotal - this.basicStatus 
      if (count > 0){
        this.$store.state.result.buttonName = 'siguiente verso'
        this.$store.state.result.redirection = '/start-basic'
        this.$store.state.step.status++
        this.$store.state.step.sentence = false
      }
    },
    basicEnd: function(){
      if (this.$store.state.result.basicSuccess !== true){
        return
      }
      
      if (this.basicStatus == this.basicTotal) {
        this.mainBasicSuccessMessage = '¡Poema superado!'
        this.secondaryBasicSuccessMessage = 'Modo básico superado'
        this.basicSuccess = true
      } else {
        this.mainBasicSuccessMessage = '¡Verso superado!'
        let count = this.basicTotal - this.basicStatus 
        if (count > 1){
          this.secondaryBasicSuccessMessage = 'Te quedan '+count+' versos para acabar el modo básico'
        } else {
          this.secondaryBasicSuccessMessage = 'Te queda un verso para acabar el modo básico'
        }
        this.basicStatus++
      }

    },
    vsBotStart: function(){
      if (this.$store.state.result.vsbot !== true){
        return
      }
      this.countVsbotMessage = this.$store.state.countVsbot === 1 ? 'reto superado' : 'retos superados'
    },
    vsBotEnd: function(){
        if (this.$store.state.result.vsbot !== true){
          return
        }
        this.$store.state.countVsbot++
        this.countVsbotMessage = this.$store.state.countVsbot === 1 ? 'reto superado' : 'retos superados'
        this.mainVsbotMessage = '¡Reto superado!'
        this.$refs.countVsbot.toSuccess()
    },
    progressTodayStart: function(){
      if (this.$store.state.objetiveStreak && typeof this.$store.state.result.progressToday !== 'undefined'){
        this.$store.state.result.progressToday = undefined
      }
    },
    progressTodayEnd: function(){
        if (typeof this.$store.state.result.progressToday === 'undefined' || this.$store.state.result.progressToday.increaseWords < 1){
          return
        }
        if (this.$store.state.result.progressToday.objetiveStreak){
          this.mainProgressTodayMessage = '¡Día de racha conseguido!'
        } else if(this.$store.state.result.progressToday.increaseWords > 1){
          this.mainProgressTodayMessage = '+ '+this.$store.state.result.progressToday.increaseWords+' palabras acertadas'
        } else {
          this.mainProgressTodayMessage = '+ 1 palabra acertada'
        }
        this.$store.state.increaseWordsToday += this.$store.state.result.progressToday.increaseWords
        this.$store.commit('checkGoalStreak',this.$store.state.result.progressToday.objetiveStreak)
    },
    increaseCoinsEnd: function(){
        if (!this.$store.state.result.increaseCoins || this.$store.state.result.increaseCoins === 0){
          return
        }
        if (this.$store.state.result.increaseCoins > 1){
          this.mainIncreaseCoinsMessage = '+ '+this.$store.state.result.increaseCoins+' puntos conseguidos!'
        } else {
          this.mainIncreaseCoinsMessage = '+ un punto conseguido!'
        }
        this.$store.state.coins += this.$store.state.result.increaseCoins
    }
  }
}
</script>

<style>

@keyframes imageAnimation {
    0%   { filter: invert(100%)}
    100% { filter: invert(0%)}
}

</style>
