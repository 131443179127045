<template>
  <v-footer
      color="black"
      padless
      app
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="py-4 text-center white--text"
          cols="12"
        >
        <v-btn
          color="white"
          text
          rounded
          class="my-2"
          @click="toRedirection"
        >
          {{name}}
        </v-btn>
        </v-col>
      </v-row>
    </v-footer>
</template>

<script>

import checkToken from '../../plugins/checkToken'

export default {
  name: 'footer-button',
  props: ['name','redirection'],
  data() {
    return {
      enabled: false
    }
  },
  methods: {
  	toRedirection: function(){
  	    if(!checkToken(this.$store,this.$router) || !this.enabled){
  	       return
  	    }
  		this.$store.state.currentRoute = this.redirection
  		this.$router.push(this.$store.state.currentRoute)
  	},
    enable: function(){
      this.enabled = true
    }
  }
}
</script>
